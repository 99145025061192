<script>
export default {
    props:['ods','closeModal'],
    data(){
        return {
            cancelation:''
        }
    },
    watch:{
        closeModal:{
            handler(val){
                if(val)this.$refs['modal-center'].hide();
            }
        }
    },

    methods:{
        cancel(){
            this.$emit('cancel_reason',{motif:this.cancelation});
            this.$emit('cancel',true);
        }
    }
}
</script>
<template>
    <div class="row mr-3">
        <div class="col d-flex justify-content-end">
        <b-button v-if="ods.canceled_at == null && canAny(['override_rest_ods','override_gs_ods','override_threedops_ods','override_host_ods']) && ['confirmed','approved'].includes($ods.status)  && ods.done != 1"  @click="$refs['modal-center'].toggle('#toggle-btn')" id="toggle-btn" variant="danger" class="btn-label">
            <i class="fas fa-times-circle  label-icon"></i> Annuler cet ODS
        </b-button>
        <div v-if="ods.canceled_at != null" class="d-flex align-items-center justify-content-between">
            <h4><span @click="$refs['modal-info'].toggle('#toggle-info')" id="toggle-info" class="badge badge-danger" style="cursor:pointer"> Annulé</span></h4>
            <!-- <b-button  @click="$refs['modal-info'].toggle('#toggle-info')" id="toggle-info" variant="info" class="btn-label ml-1">
                <i class="far fa-eye  label-icon"></i> Détails
            </b-button> -->
        </div>
        
        <b-modal
            id="modal-center"
            centered
            ref="modal-center"
            :title="'Valider Annulation '+ods.ref"
            title-class="font-18"
            size="lg"
            hide-footer
        >
            <div class="row">
                <div class="col">
                    <label for="">Motif d'Annulation</label>
                    <textarea class="form-control" v-model="cancelation" cols="10" rows="3"></textarea>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col text-right">
                    <b-button  @click="cancel" variant="info" class="btn-label">
                        <i class="fas fa-check  label-icon"></i> Confirmer l'Annulation
                    </b-button>
                </div>
            </div>
        
        </b-modal>

        <b-modal
            id="modal-center"
            centered
            ref="modal-info"
            :title="'Info Annulation '+ods.ref"
            title-class="font-18"
            size="lg"
            hide-footer
        >
            <div class="row">
                <div class="col">
                    <table class="table">
                        <tr>
                            <th>Annulé Par: </th>
                            <td v-if="ods.cancel_by != null"><span class="badge badge-info">{{ods.cancel_by.firstname}} {{ods.cancel_by.lastname}}</span></td>
                            <!-- <td><span class="badge badge-info">{{ods.cancel_by.firstName}} {{ods.cancel_by.lastName}}</span></td> -->
                        </tr>
                        <tr>
                            <th>Date et heure Annulation: </th>
                            <td> {{ods.canceled_at}} </td>
                        </tr>
                        <tr>
                            <th>Motif Annulation: </th>
                            <td> {{ods.canceled_reason}} </td>
                        </tr>
                    </table>
                </div>
            </div>
        </b-modal>
        </div>
    </div>
</template>